import styled from '@emotion/styled';

export const Container = styled.div` 
  display: flex;
  flex-direction: column;  
  width: 100%;
  &.archiveContainer {
    height: 95%;
  }
  &.infoTabContainer {
    height: 95%;
  }
`

export const TabsHeader = styled.ul`
  align-items: stretch;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  ${(props: any) => props.theme.small.styles};
  justify-content: flex-start;
  // overflow-x: scroll;
  position: relative;
  scrollbar-width: none;
  text-transform: uppercase;
  &::-webkit-scrollbar {
    display: none;
  }
`

interface TabProps {
  tabCount: number
}

export const Tab = styled.li`
  min-width: 100px;
  cursor: pointer;
  flex: ${({ tabCount }: TabProps) => `0 0 calc(100% / ${tabCount})`};
  padding: 16px;
  text-align: center;
  transition: color 0.3s linear;
  &.isSelected, &:hover {
    color: ${(props: any) => props.theme.secondary10};
  }
  &.isDisabled {
    color: gray; /* Gray text for disabled tab */
  }
  &.isDisabled:hover {
    /* Optionally, disable hover effects for disabled tabs */
  }
  &.isForbidden {
    color: lightgray; /* Gray text for disabled tab */
  }
  &.isForbidden:hover {
    /* Optionally, disable hover effects for disabled tabs */
  }
&.isBackorder {
    color: gray; /* Gray text for disabled tab */
  }
  &.isBackorder:hover {
    /* Optionally, disable hover effects for disabled tabs */
  }
`

interface ActiveTabIndicatorProps {
  tabCount: number
  activeTab: number
  headerHasScroll: boolean
}

export const ActiveTabIndicator = styled.div`
  width: ${({ tabCount, headerHasScroll }: ActiveTabIndicatorProps) =>  `calc(100% / ${tabCount})`};
  height: 2px;
  background-color: ${(props: any) => props.theme.secondary10};
  transform: ${({ activeTab }: ActiveTabIndicatorProps) => `translateX(calc(100% * ${activeTab}))`};
  transition: transform 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
`

export const TabContent = styled.main`      
  padding: 16px 0;
  flex-grow: 1; /* This allows the element to grow and fill the remaining space */
  height: 100%; /* Ensure it fills the height of its container */
  &.noTabs {
    padding: 0;
  }
`
