import axios from 'axios';

  
export interface ProcessingRequest {
    activity_outcome_id: string;
    product_requested: string;
  }


export const triggerImageryProcessing = async (
    token: string | undefined,
    api_url: string | undefined,
    request: ProcessingRequest,
    onSuccess: (r: any) => void,
    onError: (r: any) => void,
) => {
        
    axios
        .post(`${api_url}/aleph/processing`, request, {
            headers: { authorizationToken: `Bearer ${token}` }
        })
        .then((response: any) => {
            onSuccess(response);
        })
        .catch((error: any) => {
            onError(error.response ? error.response : error);
        });
};


export const getProcessingProducts = async (
    token: string | undefined,
    api_url: string | undefined,
    outcomeID: string
  ) => {
  
    return new Promise((resolve, reject) => {
    axios
      .get(`${api_url}/aleph/processing/?activity_outcome_id=` + outcomeID, {
        headers: { authorizationToken: `Bearer ${token}` }
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
    })
  };