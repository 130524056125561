import styled from '@emotion/styled/macro';

export const InputWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease-out;
  position: relative;
  &.warning {
    background-color: rgba(253, 226, 109, 0.9);
  }
  &.open {
    height: 35px;
    padding: 10px 0;
    opacity: 1;
  }
  svg {
    margin: auto;
    position: absolute;
    top: 9px;
    right: 10px;
    path {
      fill: ${(props: any) => props.theme.primary};
    }
  }
`;

export const ErrorText = styled.span`
  color: ${(props: any) => props.theme.errorDark};
  display: block;
  font-family: 'Public Sans', sans-serif;
  ${(props: any) => props.theme.caption.styles};
  line-height: 20px;
`;

export const HelperText = styled.span`
  color: ${(props: any) => props.theme.neutral60};
  display: block;
  font-family: 'Public Sans', sans-serif;
  ${(props: any) => props.theme.caption.styles};
  line-height: 20px;  
`;

export const BottomText = styled.div`
  max-height: 0;
  overflow: hidden;
  padding-top: 5px;
  // padding-bottom: 50px;
  transition: max-height 0.2s ease-in-out;
  &.shown {
    max-height: 40px;
  }    
`;

export const Label = styled.label`
  height: 20px;
  background-color: ${(props: any) => props.theme.neutral};
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.small.styles};
  line-height: 20px;
  margin: auto;
  pointer-events: none;
  padding: 0 2px;
  transition: all 0.2s ease-out;
  z-index: 1;
  position: absolute;
  left: 13px;
  top: 11px;
  &:hover {
    color: ${(props: any) => props.theme.primary};
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  background-color: ${(props: any) => props.theme.neutral};
  border: 1px solid ${(props: any) => props.theme.neutral40};
  border-radius: 4px;
  font-family: 'Public Sans', sans-serif;
  font-size: 13px;
  line-height: 18px;
  outline: none;
  padding: 12px;
  &.black {
    border-color: ${(props: any) => props.theme.neutral100};
    color: ${(props: any) => props.theme.neutral100};
    &::placeholder {
      color: #888;
    }
  }
  &.active {
    & + ${Label} {
      color: ${(props: any) => props.theme.neutral40};
      ${(props: any) => props.theme.caption.styles};
      transform: translateY(-22px);
    }
  }
  &:focus {
    border-color: ${(props: any) => props.theme.primary};
    & + ${Label} {
      color: ${(props: any) => props.theme.primary};
      ${(props: any) => props.theme.caption.styles};
      transform: translateY(-22px);
    }
  }
  &.error {
    border-color: ${(props: any) => props.theme.errorDark};
    & + ${Label} {
      color: ${(props: any) => props.theme.errorDark};
    }
  }
  &:disabled {
    background-color: #f6f6f6;
    border-color: #e8e8e8;
    &:hover {
      border-color: #e8e8e8;
    }
    & + ${Label} {
      background-color: transparent;
      color: #a8aaaf;
      .disabled-background {
        width: 100%;
        height: 10px;
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #f6f6f6;
      }
    }
  }
  &.with--icon {
    padding-right: 40px;
  }
`;
