import { Feature, FeatureCollection } from '@turf/helpers';
import { kml } from "@tmcw/togeojson"

import { getAllGeometryTypes, isFeature, isFeatureCollection, } from './helpers';

export const handleFileUpload = (
  file: File, 
  newPolyCallback: (poly: Feature | undefined) => void,
  errorCallback?: (error: string) => void
) => {
    
    const reader = new FileReader();
    reader.onload = (event) => {

      if (event.target && event.target.result) {
        try {
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
          let geoJson: FeatureCollection<Geometry> | undefined = undefined;

          switch (fileExtension) {
            case "geojson":
            case "json":
              geoJson = JSON.parse(event.target.result as string);
              break;

            case "kml":
              // Parse the provided KML document
              const DOMParser = require('@xmldom/xmldom').DOMParser;
              const parsedKML = new DOMParser().parseFromString(event.target.result, 'utf8');

              // Convert KML to GeoJSON
              const format = kml(parsedKML);
              const kmlConverted = JSON.stringify(format);
              geoJson = JSON.parse(kmlConverted);
              break;

            default:
              throw new Error('Not supported file type');
          }
          
          // Validates the resulting geoJson for compatibility
          if (isFeatureCollection(geoJson)) {
            const featureTypes = getAllGeometryTypes(geoJson)
            if (featureTypes.length > 1) {
              throw new Error('GeoJSON features should be all the same, points, polygons, etc.');
            }
          }
          else {
            throw new Error('GeoJSON must be a Feature or a FeatureCollection with exactly one feature.');
          }

          newPolyCallback(geoJson as unknown as Feature);          
                  
        } catch (error) {
          if (errorCallback) {
            errorCallback(error instanceof Error ? error.message : "An unknown error occurred");
          }
        }
      }
    };
    reader.readAsText(file);
  };