import { observer } from 'mobx-react-lite';
import archiveStore from 'stores/archiveStore';
import {
  CardContent,
  CardItem,
  Details,
  ResultsList,
  Row,
  ScrollButton,
  Thumbnail,
  TruncatedDiv,
} from './resultsTabs.styles';
import { useCallback, useEffect, useState } from 'react';
import { findRel, transformDateTime } from 'views/archive/helpers';
import { useStores } from 'config/hooks';
import { loadMoreItems } from 'services/archiveService';
import loadingNotificationStore from 'stores/loadingNotificationStore';
import { handleSelectCapture, handleUnselectCapture } from 'views/archive/archive';
import archiveResultsStore from 'stores/archiveResultsStore';
import { useTabContext } from 'views/archive/TabContext';
import 'react-virtualized/styles.css';
import { Column, Table } from 'react-virtualized';
import { List, AutoSizer } from 'react-virtualized';
import { ListRowProps } from 'react-virtualized';


export const ResultsTab = observer(() => {
  const { updateTabStatus } = useTabContext(); // Get the function from context
  const [showScrollButton, setShowScrollButton] = useState(true);

  const {
    rootStore: { userStore }
  } = useStores();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const loadMoreItemsIfNeeded = async () => {
    const features = archiveStore.searchResultsThumbnail?.features;
    const nextRel = findRel(archiveStore.searchResultsThumbnail?.links, 'next');

    if (features && nextRel && !isLoading) {      
      setIsLoading(true);
      loadingNotificationStore.setText('Loading more items...');
      loadingNotificationStore.setLoading(true);

      const token = await getTokenFromStore();
      const api_url = userStore.getApiUrl();
      loadMoreItems(
        token,
        api_url,
        nextRel.body,
        response => {
          if (response.data.features.length !== 0) {            
            //archiveStore.setActiveCapture(null); // if the page is still loading, but hte user already clicked something, this was unselecting the capture
            archiveStore.clearActiveCaptureData();

            // Assuming the data is structured as an object with a 'features' array
            const existingData = archiveStore.searchResultsThumbnail || { features: [], links: {}, type: '', context: {} };

            const combinedData = {
              ...existingData,
              features: [...existingData.features, ...response.data.features],
              links: response.data.links, // Updating links with the latest response
              type: response.data.type || existingData.type, // Assuming 'type' comes from the response
              context: response.data.context || existingData.context, // Assuming 'context' comes from the response
            };

            if (combinedData) {
              archiveStore.setSearchResultsThumbnail(combinedData);
            }

            archiveStore.setShowMap(true);
          }
          loadingNotificationStore.setLoading(false);
          setIsLoading(false);
        },
        (e) => {
          console.error(e);
          setIsLoading(false);
        }
      );
    }
  };

  useEffect(() => {    
    
    if (archiveStore.searchResultsThumbnail
      && findRel(archiveStore.searchResultsThumbnail?.links, 'next')
    ) {
      loadMoreItemsIfNeeded()
    }
    }, 
    [archiveStore.searchResultsThumbnail?.features.length, isLoading]);


  //{ ? (



  const getTokenFromStore = useCallback(async () => {
    const token = await userStore.getAuth0Token();
    return token;
  }, [userStore]);

  const handleClickCard = async (elem: FeatureSearch) => {
    if (archiveStore.activeCapture?.properties['satl:outcome_id'] ===
      elem.properties['satl:outcome_id']) {
      archiveStore.setActiveCapture(null);
      handleUnselectCapture(updateTabStatus);
      return;
    }

    archiveStore.setActiveCapture(elem);    
    const token = await getTokenFromStore();    
    handleSelectCapture(elem, token, updateTabStatus, { 'fitCapture': true, 'scrollResults': false });
  };

  const renderRow = ({ index, key, style }: ListRowProps) => {
    const elem = archiveStore.searchResultsThumbnail?.features[index];
    if (!elem) return null;

    return (
      <div key={key} style={style}>
        <CardItem
          isSelected={
            archiveStore.activeCapture?.properties['satl:outcome_id'] === elem.properties['satl:outcome_id']
          }
          onClick={() => handleClickCard(elem)}
          ref={(el) => archiveResultsStore.setCardRef(elem.properties['satl:outcome_id'], el)}
        >
          <CardContent>
            <div style={{ minWidth: '30px' }}>
              <Thumbnail src={elem.assets.thumbnail.href} alt="Thumbnail" />
            </div>
            <Details>
              <Row>
                <p>{transformDateTime(elem.properties.datetime)}</p>
                <p>{elem.properties.platform}</p>
              </Row>
              <div style={{ color: "#777" }}>
                <Row>
                  <p>Cloud cover: {elem.properties['eo:cloud_cover'].toFixed(1)}%</p>
                  <p><abbr title='Off nadir angle'>ONA: </abbr>{elem.properties['view:off_nadir'].toFixed(1)}°</p>
                </Row>
                <TruncatedDiv data-full-name={elem.properties['satl:outcome_id']}>
                  {elem.properties['satl:outcome_id']}
                </TruncatedDiv>
              </div>
            </Details>
          </CardContent>
        </CardItem>
      </div>
    );
  };


  return (
    <>
      {!!archiveStore.searchResultsThumbnail &&
        archiveStore.searchResultsThumbnail.features.length !== 0 ? (
        <ResultsList>
          <h1>Total captures: {archiveStore.searchResultsThumbnail.context.matched} ({archiveStore.searchResultsThumbnail.features.length} visible)</h1>
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                rowCount={archiveStore.searchResultsThumbnail ? archiveStore.searchResultsThumbnail.features.length : 0}
                rowHeight={90}
                rowRenderer={renderRow}
                //onRowsRendered={({ overscanStopIndex }) => loadMoreItemsIfNeeded(overscanStopIndex)}
              />

            )}
          </AutoSizer>
        </ResultsList>
      ) : (
        <h1>No results found</h1>
      )}
    </>
  );
});

ResultsTab.displayName = 'Results';
