import axios from 'axios';
import { parseParameters } from 'config/helpers';

export const getGeofence = (opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  const api_url = opt.api_url
  axios.get(`${api_url}/tasking/geofence/`, {
    params: parseParameters(opt),
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error);
  })
}


export const onboardUser = (opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  const api_url = opt.api_url
  axios.post(`${api_url}/auth/onboarding`, {}, {
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error);
  })
}