import axios from 'axios';

export enum StatusEnum {
    FAILURE = 'FAILURE',
    PENDING = 'PENDING',
    RECEIVED = 'RECEIVED',
    RETRY = 'RETRY',
    REVOKED = 'REVOKED',
    STARTED = 'STARTED',
    SUCCESS = 'SUCCESS',
  }
  
export interface AnalysisResult {
    status: StatusEnum;
    msg: string;
    analysis_result: any; // You might want to replace this with a more specific type if you know the structure of the analysis result
  }


  export const getOpportunities = async (
    token: string | undefined,
    api_url: string | undefined,
    product: string,
    start: string,
    end: string,    
    coordinates: [number, number, number?], // [latitude, longitude, altitude?]
    options: {},
    onSuccess: (r: any) => void,
    onError: (r: any) => void,
) => {

    const requestBody = {
        "task_name": "aleph_task",
        "project_name": "aleph_task",
        "product": Number(product),
        "max_captures": 3,
        "start": start,
        ...options,
        "end": end,
        "target": {
            "type": "Point",
            "coordinates": coordinates
        }
    }
        
    axios
        .post(`${api_url}/tasking/analysis/opportunities/`, requestBody, {
            headers: { authorizationToken: `Bearer ${token}` }
        })
        .then((response: any) => {
            onSuccess(response);
        })
        .catch((error: any) => {
            onError(error.response ? error.response : error);
        });
};

export const canAddOrder = async (
    token: string | undefined,
    api_url: string | undefined,
    product: string,
    start: string,
    end: string,    
    coordinates: [number, number, number?], // [latitude, longitude, altitude?]
    options: {},
    onSuccess: (r: any) => void,
    onError: (r: any) => void,
) => {

    const requestBody = {
        "task_name": "aleph_task",
        "project_name": "aleph_task",
        "product": Number(product),
        "max_captures": 100,
        "start": start,
        ...options,
        "end": end,
        "target": {
            "type": "Point",
            "coordinates": coordinates
        }
    }
        
    axios
        .post(`${api_url}/tasking/analysis/can-add-order/`, requestBody, {
            headers: { authorizationToken: `Bearer ${token}` }
        })
        .then((response: any) => {
            onSuccess(response);
        })
        .catch((error: any) => {
            onError(error.response ? error.response : error);
        });
};


export const getAnalysis = async (
    token: string | undefined,
    api_url: string | undefined,
    analysis_id: string,
    onSuccess: (analysis: AnalysisResult) => void,
    onError: (r: any) => void,    
) => {        
    axios
        .get(`${api_url}/tasking/analysis/status/${analysis_id}`, {
            headers: { authorizationToken: `Bearer ${token}` }
        })
        .then((response: any) => {
            onSuccess(response.data);
        })
        .catch((error: any) => {
            onError(error.response ? error.response : error);
        });
};