import { toJS } from 'mobx';
import {booleanClockwise} from '@turf/turf';
export const getRasterCoordinatesFromGeoJSON = (bbox: number[]): number[][] => {
  // For the raster images to work, the order is top left, top right, bottom right, bottom left.  
  const coordinates = [
    [bbox[0], bbox[3]], // top-left
    [bbox[2], bbox[3]], // top-right
    [bbox[2], bbox[1]], // bottom-right
    [bbox[0], bbox[1]] // bottom-left
  ];
  return coordinates;
};

export function checkClockwise(stacItem: FeatureSearch): boolean {  
  return booleanClockwise(stacItem.geometry.coordinates[0]);  
}


export const groupByOutcomeId = (arr: FeatureSearch[]): GroupedFeatureSearch[] => {
  const grouped = arr.reduce(
    (
      acc: Record<
        string,
        {
          datetime: string;
          platform: string;
          collection: string;
          ona: number;
          items: FeatureSearch[];
        }
      >,
      obj: FeatureSearch
    ) => {
      const key = obj.properties['satl:outcome_id'];
      if (!acc[key]) {
        acc[key] = {
          datetime: obj.properties.datetime,
          platform: obj.properties.platform,
          collection: obj.collection,
          ona: obj.properties['view:off_nadir'],
          items: []
        };
      }
      acc[key].items.push(obj);
      return acc;
    },
    {}
  );

  return Object.keys(grouped).map(key => ({
    outcome_id: key,
    datetime: grouped[key].datetime,
    platform: grouped[key].platform,
    collection: grouped[key].collection,
    ona: grouped[key].ona,
    items: grouped[key].items
  }));
};

export const transformDateTime = (input: string): string => {
  const date = new Date(input);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const findRel = (
  links: LinkSearchObject[] | undefined,
  rel: string
): LinkSearchObject | false => {
  const nextRelObject = links?.find(link => link.rel === rel);
  return nextRelObject || false;
};

export const addIdToProperties = (featureCollection: StacSearchResponse): StacSearchResponse => {
  const newFeatures = featureCollection.features.map(feature => {
    const newProperties = {
      ...feature.properties,
      id: feature.id,      
    };

    return {
      ...feature,
      properties: newProperties
    };
  });

  return {
    type: featureCollection.type,
    features: newFeatures,
    context: featureCollection.context,
    links: featureCollection.links
  };
};

export function assumeUTCDate(date: Date) {
  return new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  ));
}

export const formatToStartOfDayUTC = (date: Date): string => {
  const utcDate = new Date(date);

  utcDate.setUTCHours(0, 0, 0, 0);

  // Format the date to YYYY-MM-DDT00:00:00Z
  const year = utcDate.getUTCFullYear();
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}T00:00:00Z`;
};

export const formatToEndOfDayUTC = (date: Date): string => {
  const utcDate = new Date(date);

  utcDate.setUTCHours(0, 0, 0, 0);

  // Format the date to YYYY-MM-DDT00:00:00Z
  const year = utcDate.getUTCFullYear();
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}T23:59:59Z`;
}

export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const calculateOverallBBox = (features: any[]): number[] => {
  if (features.length === 0) {
    throw new Error('No features to calculate bounding box from');
  }

  let overallMinX = Infinity;
  let overallMinY = Infinity;
  let overallMaxX = -Infinity;
  let overallMaxY = -Infinity;

  features.forEach(feature => {
    if (feature.bbox[0] < overallMinX) overallMinX = feature.bbox[0];
    if (feature.bbox[1] < overallMinY) overallMinY = feature.bbox[1];
    if (feature.bbox[2] > overallMaxX) overallMaxX = feature.bbox[2];
    if (feature.bbox[3] > overallMaxY) overallMaxY = feature.bbox[3];
  });

  return [
    overallMinX,
    overallMinY,
    overallMaxX,
    overallMaxY,
  ]
};

export const geoJsonBBOX = (feature: FeatureSearch): number[] => {
  
  const coordinates = feature.geometry.coordinates[0];

  // Initialize extremes
  let minLon = Infinity;
  let maxLon = -Infinity;
  let minLat = Infinity;
  let maxLat = -Infinity;
  
  // Compute extremes
  coordinates.forEach(coord => {
    const [lon, lat] = coord;
    if (lon < minLon) minLon = lon;
    if (lon > maxLon) maxLon = lon;
    if (lat < minLat) minLat = lat;
    if (lat > maxLat) maxLat = lat;
  });

  
  return [minLon, minLat, maxLon, maxLat]
}

/**
 * Checks if bbox1 is contained inside bbox2.
 * @param {number[]} bbox1 - The first bounding box as an array of 4 numbers [x_min, y_min, x_max, y_max].
 * @param {number[]} bbox2 - The second bounding box as an array of 4 numbers [x_min, y_min, x_max, y_max].
 * @returns {boolean} - True if bbox1 is contained inside bbox2, otherwise false.
 */
export function isContained(bbox1: number[], bbox2: number[]): boolean {
  const [x1_min, y1_min, x1_max, y1_max] = bbox1;
  const [x2_min, y2_min, x2_max, y2_max] = bbox2;

  return (
    x1_min >= x2_min &&
    y1_min >= y2_min &&
    x1_max <= x2_max &&
    y1_max <= y2_max
  );
}

export function convertToGeoJSON(geometry: Geometry):JSON {
  
  // Creating a feature with the Geometry data
  const geoJSON = {
    type: 'Feature',
    geometry: {
      type: geometry.type,
      coordinates: toJS(geometry.coordinates)
    },
    properties: {} // Add any additional properties if needed
  };
  
  //@ts-ignore
  return geoJSON;
}


export function getS3Url(url: string): string {
  // Create a URL object to easily parse the URL
  const urlObj = new URL(url);

  // Extract the value of the 's' parameter from the query string
  const targetUrl = urlObj.searchParams.get('s');

  if (!!targetUrl) {

      // Decode the URL from the 's' parameter (since it's URL encoded)
      const decodedUrl = decodeURIComponent(targetUrl);
    
      return decodedUrl;
  }
  // The s parameter is not present, let's build the s3 url manually

  // Extract the hostname which contains the bucket name
  const hostname = urlObj.hostname;
  
  // Extract the path which contains the object key
  const path = urlObj.pathname;

  // The bucket name is part of the hostname in the S3 URL
  // For example, in "satellogic-production-eo-backend-catalog.s3.amazonaws.com", the bucket name is "satellogic-production-eo-backend-catalog"
  const bucketName = hostname.split('.')[0];
  
  // The object key is the part after the first slash in the pathname
  const objectKey = path.substring(1);

  // Return the S3 URI in the format s3://bucket/key
  return `s3://${bucketName}/${objectKey}`;
}
