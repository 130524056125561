import React from 'react';
import { observer } from 'mobx-react-lite';

import { Layout } from 'components/layout';
import { Map } from 'components/map';
import { CrossIcon, FiltersIcon, LoadingIcon, SearchIcon, BlankIcon } from 'components/icons';
import { ModalMetadata } from 'components/modal/modalmetadata';
import { InputDateRange } from 'components/inputdaterange';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/input';
import { CaptureCard } from 'components/capturecard';
import { ActiveFilters } from 'components/activefilters';
import { Button } from 'components/button';
import { LayerItem } from 'components/layers/layers.store';
import { ContractConstraint } from 'components/scope'

import { useStores, useQuery, useMergeState } from 'config/hooks';
import { classes } from 'config/common';
import { Project, DropdownOptions } from 'typings';
import { SCOPES } from 'config';

import {
  BottomContainer,
  ButtonWrapper,
  CaptureList,
  CapturesTotal,
  FilterButton,
  FiltersHeader,
  FiltersWrapper,
  LeftPane,
  LoadingWrapper,
  MapContainer,
  NoResults,
  TopBarTitle
} from './images.styles';

export const MyImages = observer(() => {
  const {
    rootStore: { dashboardStore, taskStore }
  } = useStores();

  React.useEffect(() => {
    taskStore.init();
  }, [taskStore]);

  const [activeLayer, setActiveLayer] = React.useState('');
  const [hoveredPin, setHoveredPin] = React.useState<string | undefined>(undefined);

  const [filtersState, setFiltersState] = useMergeState({ show: false, active: false });

  const [useLazyLoad, setLazyLoad] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [capturesTotal, setCapturesTotal] = React.useState('');

  const [mapData, setMapData] = useMergeState({ extent: undefined});

  // Get capture ID to auto zoom
  const query = useQuery();
  const captureId = query.get('id');

  React.useEffect(() => {
    dashboardStore.updateFilters({ query: captureId ?? '' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captureId])

  React.useEffect(() => {
    if (!dashboardStore.layers) {
      dashboardStore.getLayers(captureId ? captureId : '', true, false, response => {
        setCapturesTotal(response.data.count);
        // If there are more Layers, activate Lazy Load
        setLazyLoad(!!response.data.next);
      });
    }
    // eslint-disable-next-line
  }, [dashboardStore]);

  React.useEffect(() => {
    if (captureId && dashboardStore.layersByDate) {
      const defaultLayer = dashboardStore.layersByDate.find(
        (layer: any) => captureId === layer.metadata.sceneset_id || captureId.split('--')[0] === layer.metadata.sceneset_id
      );
      setActiveLayer(defaultLayer ? defaultLayer.layerId : '');
      if (defaultLayer) {
        setMapData({extent: defaultLayer.metadata.footprint})
        defaultLayer.setVisibility(true);
      }
    }
    // eslint-disable-next-line
  }, [dashboardStore.layersByDate]);

  const clearFilterHandler = async (filterToClear?: string) => {
    switch (filterToClear) {
      case 'Date':
        dashboardStore.updateFilters({ startDate: '', endDate: '' });
        dashboardStore.getLayers(dashboardStore.filters.query, true, true);
        break;
      case 'Project':
        dashboardStore.updateFilters({ projects: [] });
        dashboardStore.getLayers(dashboardStore.filters.query, true, true);
        break;
      case 'Task ID':
        dashboardStore.updateFilters({ taskId: '' });
        dashboardStore.getLayers(dashboardStore.filters.query, true, true);
        break;
      case 'Project ID':
        dashboardStore.updateFilters({ projectId: '' });
        dashboardStore.getLayers(dashboardStore.filters.query, true, true);
        break;
      default:
        setFiltersState({ active: false });
        dashboardStore.updateFilters({
          query: '',
          startDate: '',
          endDate: '',
          projects: [],
          taskId: '',
          projectId: ''
        });
        dashboardStore.getLayers(dashboardStore.filters.query, true, true);
        break;
    }
    setLazyLoad(true);
  };

  const activeFilters =
    dashboardStore.filters.startDate !== '' ||
    dashboardStore.filters.endDate !== '' ||
    dashboardStore.filters.projects.length > 0 ||
    dashboardStore.filters.taskId !== '' ||
    dashboardStore.filters.projectId !== ''

  React.useEffect(() => {
    if (!activeFilters) setFiltersState({ active: false });
    // eslint-disable-next-line
  }, [activeFilters]);

  const filterButtonText = () => {
    if (filtersState.show) return 'Close Filters';
    if (!filtersState.show && activeFilters) return 'Edit Filters';
    if (!filtersState.show && !activeFilters) return 'Filters';
  };

  const filtersButton = (
    <FilterButton onClick={() => setFiltersState({ show: !filtersState.show })}>
      {filtersState.show ? (
        <CrossIcon className="action--icon close--icon" />
      ) : (
        <FiltersIcon className="action--icon" />
      )}
      {filterButtonText()}
    </FilterButton>
  );

  React.useEffect(() => {
    return () => {
      dashboardStore.setGeneratedLayers(undefined, true);
      dashboardStore.updateFilters({
        query: captureId ? captureId : '',
        startDate: '',
        endDate: '',
        projects: [],
        taskId: '',
        projectId: ''
      });
    };
    // eslint-disable-next-line
  }, []);

  const handleMapClick = (captureId?: string) => {
    const layer = dashboardStore.layersByDate.filter((layer: LayerItem) => layer.layerId === captureId || layer.layerId === captureId?.split('--')[0])
    showCapture(layer[0])
    document.querySelector('.capture.active')?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
  }

  const showCapture = (layer: LayerItem) => {
    setMapData({extent: layer.metadata.footprint})
    setActiveLayer(layer.layerId)
    layer.setVisibility(true);
  }

  React.useEffect(() => {
    if (activeLayer !== '') {
      const activeCapture = document.getElementsByClassName('capture active')
      activeCapture[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [activeLayer])

  return (
    <Layout>
      <BottomContainer>
        <LeftPane>
          <TopBarTitle>My Captures</TopBarTitle>

          <FiltersHeader>
            <ContractConstraint requireTaskingPermission={true}>
              <Input
                className="search"
                name="idFilter"
                type="text"
                label="Search by task name or outcome id"
                value={dashboardStore.filters.query}
                icon={<SearchIcon />}
                onChange={e => {
                  setLazyLoad(true);
                  dashboardStore.updateFilters({ query: e.currentTarget.value });
                  dashboardStore.getLayers(e.currentTarget.value, true, true, response =>
                    setLazyLoad(!!response.data.next)
                  );
                }}
              />
            </ContractConstraint>

            {filtersButton}

            <ActiveFilters
              activeFilters={dashboardStore.filters}
              className="active-filters"
              clearFilterAction={(filterToClear: string) => clearFilterHandler(filterToClear)}
              show={filtersState.active}
            />
          </FiltersHeader>

          <FiltersWrapper className={classes({ show: filtersState.show })}>
            <InputDateRange
              className="dateFilter"
              name="filter-date"
              onChangeStart={date => dashboardStore.updateFilters({ startDate: date.toString() })}
              onChangeEnd={date => dashboardStore.updateFilters({ endDate: date.toString() })}
              startLabel="Date from"
              endLabel="Date to"
              maxStartDate={new Date()}
              maxEndDate={new Date()}
              monthsShown={1}
              startPlacement="bottom-start"
              endPlacement="bottom-end"
              startValue={filtersState.active ? dashboardStore.filters?.startDate : ''}
              endValue={filtersState.active ? dashboardStore.filters?.endDate : ''}
            />

            <ContractConstraint requireTaskingPermission={true}>
              <Dropdown
                className="dropdownFilter"
                label="Filter by Project"
                options={taskStore.projects?.map((item: Project) => {
                  return { value: item.name, text: item.name };
                })}
                defaultOptions={dashboardStore.filters.projects}
                onChange={(projects: DropdownOptions[]) => {
                  dashboardStore.updateFilters({ projects })
                }}
              />
            </ContractConstraint>

            <Input
              className="idFilter"
              name="taskIdFilter"
              type="text"
              label="Filter by Task ID"
              value={dashboardStore.filters.taskId}
              onChange={e => dashboardStore.updateFilters({ taskId: e.currentTarget.value })}
            />

            <Input
              className="idFilter"
              name="projectIdFilter"
              type="text"
              label="Filter by Project ID"
              value={dashboardStore.filters.projectId}
              onChange={e => dashboardStore.updateFilters({ projectId: e.currentTarget.value })}
            />

            <ButtonWrapper>
              <Button
                text="Apply filters"
                onClick={() => {
                  setLazyLoad(true);
                  setFiltersState({ show: false, active: true });
                  dashboardStore.getLayers(dashboardStore.filters.query, true, true, response =>
                    setLazyLoad(!!response.data.next), false
                  );
                }}
              />
            </ButtonWrapper>
          </FiltersWrapper>

          <CaptureList>
            {!!dashboardStore.layers && !dashboardStore.filtering && (
              <CapturesTotal>{`Showing ${dashboardStore.layers.length} of ${capturesTotal}`}</CapturesTotal>
            )}
            {dashboardStore.filtering ? (
              <LoadingWrapper>
                <LoadingIcon />
              </LoadingWrapper>
            ) : dashboardStore.layersByDate?.length === 0 && (activeFilters || dashboardStore.filters.query !== '') ? (
              <NoResults>
                <BlankIcon />
                <h1>No captures found</h1>
                <p>Try adjusting your search or reset your filters</p>
                <Button text="Reset filters" onClick={() => clearFilterHandler()} onlyText />
              </NoResults>
            ) : (
              dashboardStore.layersByDate?.map((item: LayerItem, index: number) => {
                return (
                  <CaptureCard
                    buttons={{
                      onZoomIn: () => {
                        setMapData({extent: item.metadata.footprint})
                        item.setVisibility(true);
                      }
                    }}
                    capture={item}
                    className={classes('capture', { active: activeLayer === item.layerId })}
                    key={item.layerId}
                    lazyLoad={{
                      use:
                        index ===
                        dashboardStore.layersByDate.length - Math.ceil(dashboardStore.limit / 2),
                      callback: () => {
                        const callback = (response: any) => {
                          setLoadingMore(false);
                          setLazyLoad(!!response.data.next);
                        };
                        if (useLazyLoad) {
                          setLoadingMore(true);
                          dashboardStore.getLayers(
                            dashboardStore.filters.query,
                            false,
                            false,
                            callback,
                            true // Instructs to append the pagination token only on lazy load.
                          );
                        }
                      }
                    }}
                    visibility={activeLayer === item.layerId}
                    onClick={() => showCapture(item)}
                  />
                );
              })
            )}
            {loadingMore ? (
              <LoadingWrapper>
                <LoadingIcon />
              </LoadingWrapper>
            ) : null}
          </CaptureList>
        </LeftPane>
        <MapContainer>
          {dashboardStore.layers ? (
            <>
              {dashboardStore.metadataModal ? (
                <ModalMetadata
                  isShown={dashboardStore.metadataModal}
                  layer={dashboardStore.layers[dashboardStore.activeIndex]}
                  onClose={() => dashboardStore.showMetadataModal(false)}
                />
              ) : null}
              <Map
                extent={mapData.extent}
                highlightedMarker={hoveredPin}
                layers={dashboardStore.layers}
                view="captures"
                onClick={(e, captureId) => handleMapClick(captureId)}
                onPointerMove={captureId => setHoveredPin(captureId)}
              />
            </>
          ) : (
            <LoadingWrapper>
              <LoadingIcon />
            </LoadingWrapper>
          )}
        </MapContainer>
      </BottomContainer>
    </Layout>
  );
});

MyImages.displayName = 'MyImages';
