import axios from 'axios';


export interface CloudForecastRequest {
  /**
   * Represents the target geographical point as an array [latitude, longitude].
   */
  target: [number, number];

  /**
   * The datetime from which the forecast starts.
   * Defaults to the current time if not provided.
   */
  since?: string;

  /**
   * Number of days for the forecast (maximum value is 3).
   * Defaults to 3 if not provided.
   */
  days?: number;
}


export interface CloudForecastResponse {
  /**
   * Detailed information about the location.
   */
  location: {
    /**
     * Name of the city or location.
     */
    name: string;

    /**
     * Region or administrative area of the location.
     */
    region: string;

    /**
     * Country of the location.
     */
    country: string;

    /**
     * Latitude of the location.
     */
    lat: number;

    /**
     * Longitude of the location.
     */
    lon: number;

    /**
     * Time zone identifier for the location.
     */
    tz_id: string;

    /**
     * Local time in epoch format for the location.
     */
    localtime_epoch: number;

    /**
     * Local time in human-readable format.
     */
    localtime: string;
  };

  /**
   * List of hourly cloud forecasts.
   */
  samples: CloudForecast[];
}


export interface CloudForecast {
  /**
   * The datetime corresponding to the spot sample.
   */
  datetime: string;

  /**
   * The predicted cloud coverage for the spot sample.
   * Format is in integers from 0 to 100.
   */
  cloud_cover: number;
}


export const getWeatherForecast = (
  data: CloudForecastRequest, 
  opt: any, 
  onSuccess: (response: CloudForecastResponse) => void, 
  onError: (r: any) => void
) => {
  const t = opt.token;
  const api_url = opt.api_url;
  axios.post(`${api_url}/forecast/weather/`, data, {
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    const forecast = response.data as CloudForecastResponse
    console.log(forecast);
    onSuccess(forecast);
  })
  .catch(error => {
    onError(error);
  })
}