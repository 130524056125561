import { downloadZip } from "client-zip"
import { count } from "console";
import { DownloadStream } from "dl-stream"


function isS3PresignedUrl(url: string): boolean {
  try {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    const queryParams = parsedUrl.searchParams;

    // Check if the hostname matches common S3 patterns
    const isS3Host =
      hostname.endsWith(".s3.amazonaws.com") ||
      hostname.includes(".s3-") || // Regional buckets like "bucket.s3-us-west-1.amazonaws.com"
      hostname.includes(".s3."); // Path-style buckets like "s3.us-west-1.amazonaws.com/bucket"

    // Check for required presigned URL parameters
    const hasSignature = queryParams.has("X-Amz-Signature") || queryParams.has("AWSAccessKeyId");
    const hasExpiration = queryParams.has("X-Amz-Expires") || queryParams.has("Expires");

    return isS3Host && hasSignature && hasExpiration;
  } catch (error) {
    return false; // Invalid URL
  }
}

async function getFileSizeFromPresignedUrl(url: string): Promise<number | undefined>  {
  try {
    const response = await fetch(url, { 
      method: 'GET', 
      headers: { 
        "range": "bytes=0-0",
        "Cache-Control": "no-cache", // This was needed because the Chrome cache breaks CORS somehow. This also solves the subsequent fetch with the downloadStreamer
      } 
    });

    if (response.ok) {
      const size = response.headers.get("content-range")?.split("/")[1];
      if (size) {
        return parseInt(size, 10)
      } else {
        console.warn(`Failed to fetch file size for ${url}`);
        return undefined
      }
    } else {
      console.error(`Failed to fetch file size for ${url}`);
      return undefined
    }
  } catch (error) {
    console.error(`Error: ${error}`);
    return undefined
  }
}

async function getFileSizeFromAssetUrl(url: string, authHeaders: HeadersInit): Promise<number | undefined>  {
  try {
    // Step 1: Fetch the asset URL to get the download URL
    const sizeResponse = await fetch(url, {
      method: 'HEAD', // Using HEAD to avoid downloading content
      headers: authHeaders,
      redirect: 'follow' // Follow redirects to reach the final S3 URL
    });

    if (sizeResponse.ok) {
      const size = sizeResponse.headers.get("X-Content-Length");
      return size ? parseInt(size, 10) : undefined;
    } else {
      console.error(`Failed to fetch file size from final URL: ${sizeResponse.statusText}`);
      return undefined;
    }

  } catch (error) {
    console.error(`Error: ${error}`);
    return undefined;
  }
}


export async function getFileSize(url: string, authHeaders: HeadersInit): Promise<number | undefined> {
  if (isS3PresignedUrl(url)) {
    return getFileSizeFromPresignedUrl(url)
  } else {
    return getFileSizeFromAssetUrl(url, authHeaders)
  }
}


export async function getAllAssetDetails(filteredFeatures: FeatureSearch[], authHeaders: HeadersInit) {
  // Initialize an empty dictionary to store asset details categorized by asset name
  const assetDetailsDict: Record<string, { itemId: string; href: string; filesize: number }[]> = {};

  // Iterate over each feature in the filteredFeatures array
  for (const item of filteredFeatures) {
    // Get all asset names (keys) from the assets object
    const assetNames = Object.keys(item.assets) as Array<keyof typeof item.assets>;

    // Use Promise.all to fetch file sizes concurrently for all assets of the current item
    const assetDetailsPromises = assetNames.map(async (assetName) => {
      // Extract href from the asset
      const href = item.assets[assetName].href;

      // Await the filesize for the current asset
      let filesize: number | undefined;
      try {
        filesize = await getFileSize(href, authHeaders);
      } catch (error) {
        console.error(`Failed to get file size for ${href}:`, error);
        filesize = 0; // Default value in case of error
      }

      // Return the asset details object with a default value for filesize if undefined
      return {
        itemId: item.id,
        assetName,
        href,
        filesize: filesize !== undefined ? filesize : 0, // Handle undefined by defaulting to 0
      };
    });

    // Wait for all promises to resolve
    const assetDetails = await Promise.all(assetDetailsPromises);

    // Populate the dictionary
    for (const detail of assetDetails) {
      if (!assetDetailsDict[detail.assetName]) {
        // Initialize the array if it doesn't exist
        assetDetailsDict[detail.assetName] = [];
      }
      // Push the detail object into the corresponding array
      assetDetailsDict[detail.assetName].push({
        itemId: detail.itemId,
        href: detail.href,
        filesize: detail.filesize,
      });
    }
  }

  return assetDetailsDict;
}

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024; // Size of a kilobyte
  const dm = decimals < 0 ? 0 : decimals; // Decimal places
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

// Assuming the assetDetailsDict is already populated
type AssetDetail = { filesize: number; href: string; itemId: string };

export function getSelectedAssetsInfo(assetDetailsDict: Record<string, AssetDetail[]>, selectedKeys: string[]) {
  // Filter the assetDetailsDict based on selected keys
  const filteredAssets = selectedKeys.reduce<{ hrefs: (string | Blob)[]; names: string[]; totalSize: number }>(
    (acc, key) => {
      if (assetDetailsDict[key]) {
        // Extract hrefs and sum up the filesize
        const names = assetDetailsDict[key].map(asset => {
          const parts = asset.href.split("?")[0].split("/");
          return parts.at(-1) ?? "unknown"
        });
        const hrefs = assetDetailsDict[key].map(asset => asset.href);
        const totalSize = assetDetailsDict[key].reduce((sum, asset) => sum + asset.filesize, 0);

        // Accumulate hrefs and total size
        acc.names.push(...names);
        acc.hrefs.push(...hrefs);
        acc.totalSize += totalSize;
      }
      return acc;
    },
    { hrefs: [], names: [], totalSize: 0 }
  );

  return filteredAssets;
}

type ExtraFile = { name: string, input: string | Blob };

export async function downloadFiles(
  authHeaders: HeadersInit,
  files: any[],
  extrafiles: ExtraFile[],
  fileName: string,
  onProgress: (progress: number) => void, // Callback for progress updates
  onFinished: () => void, // Callback when the operation is finished
  onError: (error: any) => void,
  abortController: AbortController // Add AbortController to allow cancellation
) {
  const { signal } = abortController;

  async function* countBytes(
    downloadStream: DownloadStream,
    extrafiles: ExtraFile[],
    onProgress: (progress: number) => void
  ) {

    onProgress(0); // Initialize progress
    let downloadedBytes = 0;

    // Process the streamed files
    for await (const response of downloadStream) {
      if (signal.aborted) {
        throw new DOMException('Download aborted', 'AbortError');
      }
      if (response.body) {
        const stream = response.body.pipeThrough(
          new TransformStream({
            transform(chunk, ctrl) {              
              if (signal.aborted) {                
                ctrl.error(new DOMException('Download aborted', 'AbortError'));
                return;
              }
              downloadedBytes += chunk.length;
              onProgress(downloadedBytes); // Update progress
              ctrl.enqueue(chunk);
            },
          })
        );
        const url = new URL(response.url)
        const parts = url.pathname.split('/')
        const filename = parts.at(-1)
        yield { name: filename, input: stream };
      }
    }

    // Add the extra files
    if (extrafiles) {
      for (const extra of extrafiles) {
        if (signal.aborted) {
          throw new DOMException('Download aborted', 'AbortError');
        }
        // Create a stream for extra file input (if it's a string, convert it to a Blob)
        let fileStream: Blob;
        if (typeof extra.input === "string") {
          fileStream = new Blob([extra.input], { type: "text/plain" });
        } else {
          fileStream = extra.input as Blob;
        }

        // Convert Blob to a stream and yield it
        const extraStream = fileStream.stream();
        yield { name: extra.name, input: extraStream };
      }
    }
  }

  try {
    // get the ZIP stream in a Blob
    const requests = files.map(
      (filename) => new Request(
        filename, 
        { headers: authHeaders, redirect: 'follow' },
      )
    );
    const stream = new DownloadStream(requests)

    const bytesCounter = countBytes(stream, extrafiles, onProgress)
    const blob = await downloadZip(bytesCounter).blob();
    // Make and click a temporary link to download the Blob
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    link.download = fileName

    link.click();
    link.remove();
    URL.revokeObjectURL(link.href);
    // Call onFinished when the download is complete
    onFinished();
  } catch (error) {    
    if (onError) onError(error);
  }
}

export async function downloadSingleFile(
    href: string, 
    headers: Record<string, string>, 
    default_content_filename: string = 'geolocated-preview'
  ) {
  try {
    // Fetch the file with the custom headers
    const response = await fetch(href, {
      method: 'GET',
      headers: new Headers(headers),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch file');
    }

    const content_url = new URL(href);
    const content_filename = content_url.pathname.split('/').pop() || default_content_filename;

    // Create a Blob from the response data
    const blob = await response.blob();

    // Create an invisible anchor element for download
    const anchor = document.createElement('a');
    const url = URL.createObjectURL(blob);
    anchor.href = url;
    anchor.download = content_filename; // Set the filename from the content-disposition header

    // Append the anchor to the body and trigger a click to start the download
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up: remove the anchor and revoke the object URL
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}
