import { InputDateRange } from 'components/inputdaterange';
import archiveStore from 'stores/archiveStore';
import { Input } from 'components/input';
import { observer } from 'mobx-react-lite';
import UploadButtonWrapper from './UploadButtonWrapper'
import { useStores } from 'config/hooks';
import { searchCollection } from 'services/archiveService';
import { toJS } from 'mobx';
import AOIModeButtonWrapper from './AOIModeButtonWrapper'
import {
  ButtonWrapper,
  ButtonsModeWrapper,
  FiltersWrapper,
  ModeButton,
  //  Title
} from 'views/archive/archive.styles';
import { Button } from 'components/button';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FiltersIcon, CalendarIcon } from 'components/icons';
import { TitleWithIcon } from './searchTab.styles';
import { MapIcon } from 'components/icons/icons';
import loadingNotificationStore from 'stores/loadingNotificationStore';
import { formatToEndOfDayUTC, formatToStartOfDayUTC, transformDateTime } from 'views/archive/helpers';
import { useTabContext } from 'views/archive/TabContext';
import ReactGA from "react-ga4";
import { addDays, endOfDay, isAfter, startOfDay } from 'date-fns';
import { convertToMultiPoint, convertToMultiPolygon, findMinMaxDates, getAllGeometryTypes, isFeature, isFeatureCollection, toCurrentOffset, toDateOffset } from './helpers';
import { mdiTune } from '@mdi/js';
import { BaseModal } from 'components/modal/basemodal';
import { ConfirmationModal } from 'components/modal/confirmationmodal';

import { Feature, FeatureCollection } from '@turf/helpers';
import { SatelliteAltOutlined, WbCloudyOutlined, WbSunnyOutlined } from '@mui/icons-material';
import { getPolygonArea, getMultiPolygonArea } from 'config/helpers';
//import { getGeometry } from '../tasking/helpers';

import mapboxgl from 'mapbox-gl';
import { currentCanvasSetup, currentCanvasTeardown } from './currentCanvasSearchMode';
import { handleFileUpload } from './uploadFileSearchMode';
import { bbox } from '@turf/turf';
import maplibregl from 'maplibre-gl';
import { drawPolygonSetup, drawPolygonTearDown } from './drawPolygonSearchMode';
import { Collapsable } from 'components/collapsable';
import { RangeSlider } from 'components/rangeslider';

/* This is for GAIA, refactor */
let setButtonDisabled_toBeExported: (disabled: boolean) => void;
export const setDisableButtonSearch = (disabled: boolean) => {
  setButtonDisabled_toBeExported(disabled);
};

export type SearchMode = 'DrawPolygon' | 'UploadGeoJSON' | 'CurrentCanvas' | 'SpecificOutcomeID';

export const SearchTabComponent =
  forwardRef((props, ref) => {

  const [disableButtonSearch, setDisableButtonSearch] = useState<boolean>(true);
  const [searchingFlag, setSearchingFlag] = useState<boolean>(true);


  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);

  const [previousAoiSearchMode, setPreviousAoiSearchMode] = useState<SearchMode | null>(null);

  const [aoiSearchMode, setAoiSearchMode] = useState<SearchMode | null>("CurrentCanvas");
  const previousModeRef = useRef<SearchMode | null>(null);

  const [modalMessage, setModalMessage] = useState<string>("");


  const [temporalSearchData, setTemporalSearchData] = useState<StacSearchResponse | null>(null);
  const [temporalMinDate, setTemporalMinDate] = useState<Date | null>(null);
  const [temporalMaxDate, setTemporalMaxDate] = useState<Date | null>(null);

  const { updateTabStatus } = useTabContext();

  const { rootStore: { notificationStore, userStore } } = useStores();

  const [cloudCoverRange, setCloudCoverRange] = useState<{ minValue: number, maxValue: number }>({ minValue: 0, maxValue: 25 });
  const [ONARange, setONARange] = useState<{ minValue: number, maxValue: number }>({ minValue: 0, maxValue: 30 });

  const modeHandlers: Record<SearchMode, { setup: (map: mapboxgl.Map) => void; teardown: (map: mapboxgl.Map) => void }> = {
    DrawPolygon: {
      setup: (map) => {
        console.log('Setting up mode DrawPolygon');
        drawPolygonSetup(map, (poly: FeatureCollection | undefined) => { archiveStore.setAoiPolygon(poly); });
      },
      teardown: (map) => {
        console.log('Tearing down mode DrawPolygon');
        drawPolygonTearDown(map);
      }
    },
    UploadGeoJSON: {
      setup: (map) => {
        console.log('Settindg up mode UploadGeoJSON');
      },
      teardown: (map) => {
        console.log('Tearing down mode UploadGeoJSON');
        setUploadedFile(undefined);
      }
    },
    CurrentCanvas: {
      setup: (map) => {
        console.log('Set up mode CurrentCanvas');
        currentCanvasSetup(map, (poly: Feature | undefined) => { archiveStore.setAoiPolygon(poly); });
      },
      teardown: (map) => {
        console.log('Tearing down mode CurrentCanvas');
        currentCanvasTeardown(map);
      }
    },
    SpecificOutcomeID: {
      setup: (map) => {
      },
      teardown: (map) => {
      }
    }
  };

  useImperativeHandle(ref, () => ({
    triggerSearch: () => {
      setAoiSearchMode("SpecificOutcomeID");
      handleSearchClick();
    },
    updateOutcomeID: (outcome_ids: Set<string>) => {
      const ids = Array.from(outcome_ids);
      setPreviousAoiSearchMode(aoiSearchMode);
      setAoiSearchMode("SpecificOutcomeID");
      archiveStore.setDropdownFilterValues("satl:outcome_id", ids);
      archiveStore.updateArgs("satl:outcome_id", ids, "in");
    }
  }));

  useEffect(() => {

    const map: mapboxgl.Map | undefined = archiveStore.mapRefStore?.getMap()
    
    //XXX: map.loaded() isn´t workign as expected. In local, map.loaded() is true, while in dev and prod is not, even if the internal map._local is true. Not sure why.
    // Anywaty, the ref to the map is created after the map loads (see archive.tsx) ,  
    // so it is infact loaded when this useEffect is triggered.  map.loaded() isnt working as expected. O     

    if (!!map) { 

      if (aoiSearchMode && previousModeRef.current !== aoiSearchMode) {
        if (previousModeRef.current) {
          modeHandlers[previousModeRef.current].teardown(map);
        }

        modeHandlers[aoiSearchMode].setup(map);

        previousModeRef.current = aoiSearchMode;
      }
    }
    //console.log("end use effect")
  }, [aoiSearchMode, archiveStore.mapRefStore]);


  // XXX: Initial setup, does it make any sense ?
  useEffect(() => {
    // Set the defaults as args, if not, we need the user to change a value    
    archiveStore.updateArgs("eo:cloud_cover", [cloudCoverRange.minValue, cloudCoverRange.maxValue], "between");
    archiveStore.updateArgs("view:off_nadir", [ONARange.minValue, ONARange.maxValue], "between");
  }, []);

  // XXX: This is for GAIA , remove when possible
  setButtonDisabled_toBeExported = setDisableButtonSearch;

  // ------------- Input validators and effects --------------------
  useEffect(() => {

    if (!archiveStore.aoiPolygon) {

      archiveStore.setAOIArea(undefined);
      setDisableButtonSearch(true);

    } else {

      if (isFeatureCollection(archiveStore.aoiPolygon)) {
        const featureTypes = getAllGeometryTypes(archiveStore.aoiPolygon);

        if (featureTypes.includes("Point")) {

          archiveStore.setAOIArea(undefined);
          setDisableButtonSearch(false);

        } else {
          let totalArea = 0;

          archiveStore.aoiPolygon.features.forEach((feature) => {

            //@ts-ignore, geometry does have coordinates, there is a problem with the type

            if (feature.geometry.type == "Polygon") {
              totalArea = totalArea + getPolygonArea(feature.geometry.coordinates)
            } else if (feature.geometry.type == "MultiPolygon") {
              totalArea = totalArea + getMultiPolygonArea(feature.geometry.coordinates)
            }
          })

          archiveStore.setAOIArea(Number(totalArea))
          setDisableButtonSearch(archiveStore.aoiArea > 10000000);
        }

      } else {
        if (archiveStore.aoiPolygon.geometry.type === "Polygon") {
          const polygonArea = getPolygonArea(archiveStore.aoiPolygon.geometry.coordinates)

          archiveStore.setAOIArea(Number(polygonArea))
          setDisableButtonSearch(archiveStore.aoiArea > 10000000);
        }
      }
    }
  }, [
    archiveStore.aoiPolygon,
  ]);


  // ---------------------------------------- Input fields handlers --------------------------------------------------------
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "satl:outcome_id" && value !== "") {
      if (aoiSearchMode !== "SpecificOutcomeID") {
        setPreviousAoiSearchMode(aoiSearchMode);
        setAoiSearchMode("SpecificOutcomeID");
      }
    } else {
      if (previousAoiSearchMode) {
        setAoiSearchMode(previousAoiSearchMode);
      }
    }

    if (value.includes(",")) {
      const parsedValue = value.split(",").map((item) => item.trim());

      archiveStore.setDropdownFilterValues(name, parsedValue);
      archiveStore.updateArgs(name, parsedValue, "in");
    } else {
      const parsedValue =
        e.target.type === 'number' ? (value === '' ? undefined : parseFloat(value)) : value;

      archiveStore.setDropdownFilterValues(name, parsedValue);
      archiveStore.updateArgs(name, parsedValue, "=");
    }

  };

  const handleCloudCoverInputChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setCloudCoverRange({ minValue: newValue[0], maxValue: newValue[1] });
      archiveStore.updateArgs("eo:cloud_cover", [newValue[0], newValue[1]], "between");
    }
  };

  const handleONAInputChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setONARange({ minValue: newValue[0], maxValue: newValue[1] });
      archiveStore.updateArgs("view:off_nadir", [newValue[0], newValue[1]], "between");
    }
  };


  // ---------------------------------------- Search functions --------------------------------------------------------
  const handleSearchClick = async () => {

    if (aoiSearchMode && archiveStore.mapRefStore) modeHandlers[aoiSearchMode].teardown(archiveStore.mapRefStore?.getMap());

    if (!!archiveStore.activeCapture) {
      archiveStore.setActiveCapture(null);
      archiveStore.clearActiveCaptureData();
      archiveStore.setActiveProductData(null);
    }

    setSearchingFlag(true);
    setDisableButtonSearch(true);

    loadingNotificationStore.setText('Searching... ');
    loadingNotificationStore.setLoading(true);
    loadingNotificationStore.setNoResults(false);


    try {

      let searchCollectionParams;

      if (aoiSearchMode === "SpecificOutcomeID") {

        // Step 1: Filter the 'args' array to include only the 'satl:outcome_id' filters
        const updatedArgs = toJS(archiveStore.filtersQueriables.filter.args).filter(
          (filter: any) =>
            filter.args.some((arg: any) => arg.property === "satl:outcome_id")
        );

        // Step 2: Create a new object with the updated 'args' array while keeping other properties intact
        const updatedFilter = {
          ...archiveStore.filtersQueriables.filter, // Spread operator to copy all properties
          args: updatedArgs // Override 'args' with the filtered array
        };

        searchCollectionParams = {
          collections: ['quickview-visual-thumb'],
          limitSearchAmount: '10',
          filter:
            updatedFilter.args.length > 0
              ? updatedFilter
              : undefined
        }

      } else {
      
        const isFeatureCollectionType = isFeatureCollection(archiveStore.aoiPolygon);      
        const geometryTypes = isFeatureCollectionType ? getAllGeometryTypes(archiveStore.aoiPolygon) : [];
        const geometryType = geometryTypes[0];

        const geometry = isFeature(archiveStore.aoiPolygon) ? (archiveStore.aoiPolygon as any).geometry :
          archiveStore.aoiPolygon.length === 1 ? (archiveStore.aoiPolygon[0] as any).geometry :
            getAllGeometryTypes(archiveStore.aoiPolygon)[0] === "Point" ? convertToMultiPoint(archiveStore.aoiPolygon).geometry : 
              convertToMultiPolygon(archiveStore.aoiPolygon, geometryType).geometry

        searchCollectionParams = {
          startDate: formatToStartOfDayUTC(archiveStore.dateStart),
          endDate: formatToEndOfDayUTC(archiveStore.dateEnd),
          collections: ['quickview-visual-thumb'],
          limitSearchAmount: '10',
          geometry: geometry,
          filter:
            archiveStore.filtersQueriables.filter.args.length > 0
              ? archiveStore.filtersQueriables
              : undefined,
        }
      }
      console.log(archiveStore.dateStart, archiveStore.dateEnd)
      console.log(searchCollectionParams)


      const getTokenFromStore = async () => {
        const token = await userStore.getAuth0Token();
        return token;
      };

      const token = await getTokenFromStore();
      const api_url = userStore.getApiUrl();

      const searchData = await searchCollection(token, api_url, searchCollectionParams);
      // Send a custom event
      ReactGA.event({
        category: "search",
        action: JSON.stringify(searchCollectionParams),
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

      if (searchData && searchData.features.length > 0) {
        archiveStore.setSearchResultsThumbnail(searchData);
        archiveStore.setShowMap(true);
        loadingNotificationStore.setLoading(false);

        updateTabStatus("Results", true);
        archiveStore.setSelectedTab("Results");

      } else {
        loadingNotificationStore.setLoading(false);
        loadingNotificationStore.setNoResults(true);

        // Helper functions to adjust dates
        const expandByDays = (startDate: Date, endDate: Date, days: number) => {
          const newStartDate = addDays(startDate, -days);
          const newEndDate = addDays(endDate, days);
          return { newStartDate: startOfDay(newStartDate), newEndDate: endOfDay(newEndDate) };
        };


        const currentStartDate = new Date(archiveStore.dateStart);
        const currentEndDate = new Date(archiveStore.dateEnd);

        const expandedRange = expandByDays(currentStartDate, currentEndDate, 30 * 6);

        if (isAfter(expandedRange.newEndDate, new Date())) {
          expandedRange.newEndDate = endOfDay(new Date());
        }

        const newSearchParams = {
          ...searchCollectionParams,
          startDate: formatToStartOfDayUTC(expandedRange.newStartDate),
          endDate: formatToEndOfDayUTC(expandedRange.newEndDate),
        };

        loadingNotificationStore.setText('Searching...');
        loadingNotificationStore.setLoading(true);

        setModalMessage("searching")
        loadingNotificationStore.setModal(true);
        const api_url = userStore.getApiUrl();

        const searchData = await searchCollection(token, api_url, newSearchParams);

        if (searchData.context.matched > 0) {
          setTemporalSearchData(searchData);

          const { minDate, maxDate } = findMinMaxDates(searchData);
          if (!!minDate && !!maxDate) {
            setTemporalMinDate(minDate);
            setTemporalMaxDate(maxDate);
          }
          console.log(temporalMinDate)
          setModalMessage("thereismoredata")
        } else {
          // No data found in this search area
          setSearchingFlag(false);
          setDisableButtonSearch(false);
          loadingNotificationStore.setLoading(false);

          // If no results were found. Re-run the search mode setup
          // allowing users to modify/correct the search area.
          if (aoiSearchMode && archiveStore.mapRefStore) modeHandlers[aoiSearchMode].setup(archiveStore.mapRefStore?.getMap());

          setTemporalMinDate(expandedRange.newStartDate);
          setTemporalMaxDate(expandedRange.newEndDate);
          setModalMessage("nodata");
        }

      }
    } catch (error) {
      console.error("Error processing search collection:", error);
      loadingNotificationStore.setLoading(false);
    }

    setDisableButtonSearch(false);
  };

  const handleExpandSearch = () => {
    if (temporalMaxDate && temporalMinDate) {
      archiveStore.setDateEnd(temporalMaxDate);
      archiveStore.setDateStart(temporalMinDate);
      handleSearchClick()
    }
  };

  const handleCollectDataHere = () => {
    ReactGA.event({
      category: "needdata",
      action: JSON.stringify(archiveStore.aoiPolygon),
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    loadingNotificationStore.setModal(false);
  }

  const handleRefineSearch = () => {
    loadingNotificationStore.setLoading(false);
    loadingNotificationStore.setNoResults(false);
    loadingNotificationStore.setModal(false);
  };

  return (
    <>
      <BaseModal
        isShown={loadingNotificationStore.modalIsOpen && modalMessage === "searching"}
        hide={() => { }}>
        <div>
          No data was found for the given filters.
          <br />
          <br />
          Expanding search in time to see if there is more data...
        </div>
      </BaseModal>

      <ConfirmationModal
        isShown={loadingNotificationStore.modalIsOpen && modalMessage === "thereismoredata"}
        title={'We found more data'}
        text={`
          There ${temporalSearchData?.context.matched && temporalSearchData?.context.matched > 1 ? "are" : "is"} 
          ${temporalSearchData?.context.matched} capture${temporalSearchData?.context.matched && temporalSearchData?.context.matched > 1 ? "s" : ""} 
          in the ${transformDateTime(temporalMinDate ? temporalMinDate.toISOString() : "")} - ${transformDateTime(temporalMaxDate ? temporalMaxDate.toISOString() : "")} range.
          Do you want to expand the search to that period of time?
          `}
        acceptText={'Expand time range and search'}
        cancelText={'No thanks.'}
        onCancel={() => loadingNotificationStore.setModal(false)}
        onClose={() => loadingNotificationStore.setModal(false)}
        onConfirm={handleExpandSearch}
      />

      <ConfirmationModal
        isShown={loadingNotificationStore.modalIsOpen && modalMessage === "nodata"}
        title={'No data found'}
        text={`
          Oops! It seems that there is no data in the archive for the provided filters. <br/><br/>
          We also tried to find data within the 
          ${transformDateTime(temporalMinDate ? temporalMinDate.toISOString() : "")} - ${transformDateTime(temporalMaxDate ? temporalMaxDate.toISOString() : "")} 
          period but none was found.
          <br/><br/>
          Would you like us to collect data here?
          `}
        acceptText={'Please capture data here!'}
        cancelText={'I will refine my search'}
        onCancel={handleRefineSearch}
        onClose={() => loadingNotificationStore.setModal(false)}
        onConfirm={handleCollectDataHere}
      />

      <FiltersWrapper>
        <div className="inner">
          <TitleWithIcon>
            <MapIcon className="action--icon" />
            <h1>Area of interest</h1>
          </TitleWithIcon>

          <ButtonsModeWrapper>
            <ModeButton active={aoiSearchMode === "CurrentCanvas"}>
              <AOIModeButtonWrapper
                text="Current Canvas"
                medium
                onClick={() => {
                  setAoiSearchMode("CurrentCanvas");
                }}
                secondary
                disabled={aoiSearchMode === "SpecificOutcomeID"}
              />
            </ModeButton>

            <ModeButton active={aoiSearchMode === "DrawPolygon"}>
              <AOIModeButtonWrapper
                text="Draw Polygon"
                medium
                onClick={() => {
                  setAoiSearchMode("DrawPolygon");
                }}
                secondary
                disabled={aoiSearchMode === "SpecificOutcomeID"}
              />
            </ModeButton>

            <ModeButton active={aoiSearchMode === "UploadGeoJSON"}>
              <UploadButtonWrapper
                text="Upload AOI"
                medium
                onFileUpload={(file: any) => {

                  setAoiSearchMode("UploadGeoJSON");
                  setUploadedFile(file);

                  if (!!file) handleFileUpload(file, (poly: Feature | undefined) => {

                    archiveStore.setAoiPolygon(poly);

                    if (poly) {
                      //@ts-ignore
                      const aoibbox = bbox(poly);
                      const bounds = new maplibregl.LngLatBounds(
                        [aoibbox[0], aoibbox[1]], // southwest corner
                        [aoibbox[2], aoibbox[3]]  // northeast corner
                      );
                      archiveStore.maplibreMap?.fitBounds(
                        bounds, {
                        essential: true,
                        duration: 750,
                        padding: 20,
                      })
                    }
                  }, (error) => {
                    notificationStore.add({ description: error });
                  });
                }}
                secondary
                disabled={aoiSearchMode === "SpecificOutcomeID"}
              />
            </ModeButton>

          </ButtonsModeWrapper>
          <div>
            <br />
          </div>
          {archiveStore.aoiArea ? (
            <>
              <TitleWithIcon>
                Search Area: {archiveStore.aoiArea.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  notation: 'compact',
                  compactDisplay: 'short'
                })} km<sup>2</sup>
              </TitleWithIcon>

              {aoiSearchMode === "UploadGeoJSON" && uploadedFile?.name &&
                <TitleWithIcon>
                  <div style={{ textOverflow: "ellipsis", width: "100%", overflow: "hidden", whiteSpace: "nowrap" }}> Search aoi: {uploadedFile?.name} </div>
                </TitleWithIcon>
              }
            </>
          ) :
            undefined
          }
        </div>

        <div className="inner">

          <TitleWithIcon>
            <CalendarIcon className="action--icon" />
            <h1>Date range</h1>
          </TitleWithIcon>

          <InputDateRange
            disabled={aoiSearchMode === "SpecificOutcomeID"}
            name="filter-date"
            startLabel="Since"
            endLabel="Until"
            maxStartDate={new Date()}
            maxEndDate={new Date()}
            monthsShown={1}
            startPlacement="bottom-start"
            endPlacement="bottom-end"

            onChangeStart={date => {
              const startDate = toDateOffset(date);
              archiveStore.setDateStart(startDate);
            }}

            onChangeEnd={date => {
              const endDate = toDateOffset(date);
              archiveStore.setDateEnd(endDate);
            }}

            startValue={toCurrentOffset(archiveStore.dateStart)}
            endValue={toCurrentOffset(archiveStore.dateEnd)}
          />
        </div>
        <div className="inner">
          <TitleWithIcon>
            <FiltersIcon className="action--icon" />
            <p>Capture constraints</p>
          </TitleWithIcon>
                      
          <RangeSlider
            title={"Cloud cover"}
            values={cloudCoverRange}
            id={'cloud_cover'}            
            range={{lower: 0, upper: 100}}
            unit={"%"}
            disabled={aoiSearchMode === "SpecificOutcomeID"}
            onChange={handleCloudCoverInputChange}
            leftIcon={<WbSunnyOutlined />}
            rightIcon={<WbCloudyOutlined />}            
          />            

          <RangeSlider
            title={"Off nadir angle"}
            values={ONARange}
            id={'ona_range'}            
            range={{lower: 0, upper: 60}}
            unit={"°"}
            disabled={aoiSearchMode === "SpecificOutcomeID"}
            onChange={handleONAInputChange}
            leftIcon={<SatelliteAltOutlined sx={{ transform: "rotate(45deg)" }} />}
            rightIcon={<SatelliteAltOutlined sx={{ transform: "rotate(0deg)" }} />}            
          />            
          
        </div>
        <div style={{ height: "100%" }}>

          <Collapsable title={'Advanced filters'} icon={mdiTune}>
            <Input
              name="satl:outcome_id"
              label="Outcome ID"
              onChange={handleInputChange}
              helperText='Supports multiple ids separated by comma, disables all other filters'
              type="text"
              value={archiveStore.dropdownFilterValues['satl:outcome_id'] as string}
            />

            <Input
              disabled={aoiSearchMode === "SpecificOutcomeID"}
              name="platform"
              label="Satellite"
              onChange={handleInputChange}
              helperText='Satellite name, i.e. newsat30. Supports multiple satellites separated by comma.'
              type="text"
              value={archiveStore.dropdownFilterValues['platform'] as string}
            />
          </Collapsable>
        </div>
        <div id="do_search" className="inner">
          <ButtonWrapper>
            <Button
              text={aoiSearchMode !== "SpecificOutcomeID" ? loadingNotificationStore.loading ? "Searching..." : disableButtonSearch ? "Search area is too big." : "Search" : "Search"}
              onClick={handleSearchClick}
              disabled={aoiSearchMode !== "SpecificOutcomeID" && disableButtonSearch && !!searchingFlag}
            />
          </ButtonWrapper>

        </div>
      </FiltersWrapper>
    </>
  );

}
);

SearchTabComponent.displayName = 'Search';

export const SearchTab = observer(SearchTabComponent);